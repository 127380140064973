
import { defineComponent } from "vue";
import CommentList from "@/components/CommentList.vue";

export default defineComponent({
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CommentList,
  },
});
