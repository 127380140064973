<template>
  <button :class="classList" :disabled="disabled" :style="style">
    <slot></slot>
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import cx from "classnames";

export default defineComponent({
  name: "Button",
  props: {
    style: {
      type: Object,
      required: false,
    },
    type: {
      type: String,
      default: "default",
      validator(value: string) {
        return ["primary", "default"].includes(value);
      },
    },
    circle: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "default",
      validator(value: string) {
        return ["xs", "small", "large", "default"].includes(value);
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classList(): string {
      return cx("btn", "btn-" + this.type, "btn-" + this.size, {
        "btn-circle": this.circle,
        "btn-loading": this.loading,
        "btn-disabled": this.disabled,
      });
    },
  },
});
</script>

<style lang="less">
@primary-color: #1cca96;

.btn {
  box-sizing: border-box;
  text-align: center;
}

.btn-default {
  color: @primary-color;
  background-color: transparent;
  height: 58px;
  line-height: 58px;
  font-size: 24px;
  border-radius: 16px;
  border: 1px solid @primary-color;
}

.btn-small {
  color: @primary-color;
  background-color: transparent;
  height: 45px;
  line-height: 43px;
  font-size: 18px;
  border-radius: 16px;
  border: 1px solid @primary-color;
}

.btn-xs {
  color: @primary-color;
  background-color: transparent;
  height: 30px;
  line-height: 22px;
  font-size: 16px;
  border-radius: 8px;
  padding: 4px 8px;
  border: 1px solid @primary-color;
}

.btn-primary {
  color: #fff;
  background-color: @primary-color;
}

.btn-circle {
  border-radius: 30px;
}

.btn-primary:active {
  border-color: darken(@primary-color, 10%);
  background-color: darken(@primary-color, 10%);
}

.btn-default:active {
  border-color: darken(@primary-color, 10%);
}

.btn:disabled {
  cursor: not-allowed;
  opacity: 0.9;
}
</style>
