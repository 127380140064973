<template>
  <a-modal
    title=""
    :footer="null"
    :visible="visible"
    class="loginModal"
    width="656px"
    @cancel="$emit('cancel')"
    :afterClose="afterClose"
  >
    <div class="modal-content">
      <img src="@/assets/ic_playing.svg" class="modal-icon" />
      <template v-if="step === 1">
        <div class="modal-title">登录</div>
        <div class="switch-tab">
          <div
            :class="['switch-tab-item', { active: loginType === 'password' }]"
            @click="
              () => {
                this.loginType = 'password';
                this.resetFields();
              }
            "
          >
            密码登录
          </div>
          <div
            :class="['switch-tab-item', { active: loginType === 'verifyCode' }]"
            @click="
              () => {
                this.loginType = 'verifyCode';
                this.resetFields();
              }
            "
          >
            验证码登录
          </div>
        </div>
        <a-form layout="horizontal" v-if="loginType === 'password'">
          <a-form-item label="" hasFeedback v-bind="validateInfos.loginName">
            <a-input
              :placeholder="'手机、邮箱地址'"
              v-model:value="modelRef.loginName"
              size="large"
            />
          </a-form-item>
          <a-form-item label="" hasFeedback v-bind="validateInfos.password">
            <a-input-password
              placeholder="密码"
              v-model:value="modelRef.password"
              size="large"
            />
          </a-form-item>
        </a-form>
        <a-form layout="horizontal" v-if="loginType === 'verifyCode'">
          <a-form-item label="" hasFeedback v-bind="validateInfos.phone">
            <a-input
              :placeholder="'手机'"
              v-model:value="modelRef.phone"
              size="large"
            />
          </a-form-item>
        </a-form>
        <i-button type="primary" style="width: 100%" @click="login"
          >登录</i-button
        >
        <div class="login-hint">可以用SLEEPON帐号进行登录</div>
        <div class="bottom-link">
          <div class="link-text" @click="$emit('forgetPassword')">
            忘记密码?
          </div>
          <div class="link-text" @click="$emit('register')">注册</div>
        </div>
      </template>
      <template v-if="step === 2">
        <div class="modal-title">输入验证码</div>
        <div class="vcode-text">
          我们已经将4位验证码发送到你的手机 +86 {{ modelRef.phone }}
        </div>
        <a-form layout="horizontal">
          <a-form-item label="" hasFeedback v-bind="validateInfos.code">
            <a-input placeholder="验证码" v-model:value="modelRef.code" />
          </a-form-item>
        </a-form>
        <div class="link-text" @click="handleResend">
          {{ time === 0 ? "没有收到验证码?" : time + "s" }}
        </div>
        <i-button
          type="primary"
          style="width: 100%; margin-top: 65px"
          @click="nextStep"
          class="bottomButton"
          >下一步</i-button
        >
      </template>
    </div>
  </a-modal>
</template>

<script>
import sha1 from "sha1";
import { reactive, nextTick } from "vue";
import { Form } from "ant-design-vue";
import { post, get, userServer } from "@/util";

const useForm = Form.useForm;

export default {
  name: "LoginModal",

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      step: 1,
      time: 0,
      loginType: "password",
    };
  },

  methods: {
    login() {
      const loginType = this.loginType;

      if (loginType === "password") {
        this.validate(["loginName", "password"]).then((values) => {
          const params = { password: sha1(values.password) };
          if (/^\d+$/.test(values.loginName)) {
            params.type = 2;
            params.phone = values.loginName;
            params.area_code = "86";
          } else {
            params.type = 1;
            params.email = values.loginName;
          }
          this.loginRequest(params);
        });
      } else {
        this.validate(["phone"]).then((values) => {
          this.sendCode({
            area_code: "86",
            phone: values.phone,
          });
          this.step = 2;
        });
      }
    },

    nextStep() {
      this.validate(["code"]).then((values) => {
        this.loginRequest({
          type: 2,
          area_code: "86",
          phone: this.modelRef.phone,
          code: values.code,
        });
      });
    },

    timer() {
      clearTimeout(this.$timer);
      if (this.time > 0) {
        this.$timer = setTimeout(() => {
          this.time -= 1;
          this.timer();
        }, 1000);
      }
    },

    sendCode(params) {
      post(userServer + "/v1/api/code", {
        type: 2,
        logic: 1,
        ...params,
      }).then(() => {
        this.time = 60;
        this.timer();
      });
    },

    async handleResend() {
      if (this.time === 0) {
        await nextTick();
        this.sendCode({
          area_code: "86",
          phone: this.modelRef.phone,
        });
      }
    },

    loginRequest(params) {
      get(
        params.code
          ? userServer + "/v1/api/login/verify"
          : userServer + "/v1/api/login",
        params
      ).then((data) => {
        this.$store.dispatch("afterLogin", data);
        this.$emit("cancel");
        this.$router.push("/");
      });
    },

    afterClose() {
      this.step = 1;
      this.time = 0;
      this.loginType = "password";
      this.resetFields();
    },
  },

  setup(props, context) {
    const modelRef = reactive({
      loginName: "",
      phone: "",
      email: "",
      password: "",
      code: "",
    });

    const rulesRef = reactive({
      loginName: [
        {
          required: true,
          message: "请输入手机或者邮箱",
        },
        {
          pattern:
            /^(13[0-9]|14[5|7]|15[0|1|2|3|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$|^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
          message: "请输入有效的手机或者邮箱",
        },
      ],
      phone: [
        {
          required: true,
          message: "请输入手机号码",
        },
        {
          pattern:
            /^(13[0-9]|14[5|7]|15[0|1|2|3|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/,
          message: "请输入有效的手机",
        },
      ],
      email: [
        {
          required: true,
          message: "请输入邮箱",
        },
        {
          type: "email",
          message: "请输入有效的邮箱",
        },
      ],
      password: [
        {
          required: true,
          message: "请输入密码",
        },
        {
          min: 6,
          message: "密码最少6个字符",
        },
        {
          max: 32,
          message: "密码不能超过32个字符",
        },
      ],
      code: [
        {
          required: true,
          message: "请输入验证码",
        },
      ],
    });

    const { validate, validateInfos, resetFields } = useForm(
      modelRef,
      rulesRef
    );

    return {
      validate,
      validateInfos,
      modelRef,
      resetFields,
    };
  },
};
</script>

<style lang="less" scoped>
.modal-content {
  display: flex;
  flex-flow: column nowrap;
}

.modal-title {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: #ffffff;
  margin-bottom: 32px;
}
.modal-icon {
  width: 50px;
  margin-bottom: 15px;
}
.login-hint {
  font-size: 16px;
  line-height: 22px;
  margin-top: 32px;
  margin-bottom: 32px;
  color: #fff;
}
.bottom-link {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  .link-text {
    flex: 0 0 auto;
  }
}

.link-text {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #1cca96;
  cursor: pointer;
}

.vcode-text {
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  margin-bottom: 20px;
}

.switch-tab {
  margin-bottom: 33px;
  &-item {
    cursor: pointer;
    display: inline-block;
    font-size: 24px;
    color: rgba(255, 255, 255, 0.6);
    margin-right: 32px;
    &.active {
      font-weight: 600;
      color: #ffffff;
    }
  }
}
</style>

<style lang="less">
.loginModal {
  .ant-modal-close-x {
    display: none;
  }
}
</style>
