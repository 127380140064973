<template>
  <div :class="['sidebar', { open }]">
    <div class="sidebar-top">
      <span class="close-icon" @click="$emit('closeSideBar')"></span>
    </div>
    <div class="user-info" v-if="userInfo">
      <div class="user-info-avatar">
        <img src="@/assets/default_avatar@2x.png" />
      </div>
      <div class="user-info-text">
        <div>
          <span @click="$emit('openSettingBar')" style="cursor: pointer">
            {{ userInfo.nickname }}
            <span class="arrow-icon">
              <img src="@/assets/arrow.svg" />
            </span>
          </span>
        </div>
        <div class="user-info-text-desc">{{ userInfo.email }}</div>
      </div>
    </div>
    <div class="user-info" v-else>
      <div class="user-info-avatar">
        <img src="@/assets/default_avatar@2x.png" />
      </div>
      <div class="user-info-text">
        <router-link to="/login" style="color: #fff">立即登录</router-link>
      </div>
    </div>
    <div class="category-container">
      <div class="category-list">
        <div class="category-wrapper" ref="container">
          <div
            :class="['category-item', { active: index === selectedCategory }]"
            v-for="(item, index) in categories"
            :key="index"
            @click="selectCategory(index)"
          >
            {{ item.category.category_name }}
          </div>
        </div>
      </div>
      <div class="album-container" v-if="categoryVideos">
        <Album
          v-for="(video, index) in categoryVideos"
          :video="video"
          :key="video.video.id"
          :isActive="selectedVideo && selectedVideo.video.id === video.video.id"
          :isPlaying="isPlaying"
          @select="selectVideo({ video, index })"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Album from "@/components/Album.vue";

export default {
  props: {
    open: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  components: {
    Album,
  },

  computed: {
    ...mapGetters(["categories", "categoryVideos"]),
    ...mapState({
      selectedCategory(state) {
        return state.selectedCategory;
      },
      selectedVideo(state) {
        return state.selectedVideo;
      },
      isPlaying(state) {
        return state.isPlaying;
      },
      userInfo(state) {
        return state.userInfo;
      },
    }),
  },

  mounted() {
    this.lazyLoad();

    this.bindScroll();
  },

  updated() {
    this.lazyLoad();
  },

  methods: {
    selectCategory(category) {
      this.$store.commit({
        type: "SELECT_CATEGORY",
        payload: {
          index: category,
        },
      });
    },

    selectVideo(payload) {
      this.$store.dispatch({
        type: "selectVideo",
        payload: {
          ...payload,
          changeCategory: true,
          play: true,
        },
      });
    },

    lazyLoad() {
      var lazyImages = [].slice.call(
        document.querySelectorAll(".album-container img.lazy")
      );

      // if ("IntersectionObserver" in window) {
      //   // Create new observer object
      //   let lazyImageObserver = new IntersectionObserver(function (
      //     entries,
      //     observer
      //   ) {
      //     entries.forEach(function (entry) {
      //       if (entry.isIntersecting) {
      //         let lazyImage = entry.target;
      //         lazyImage.src = lazyImage.dataset.src;
      //         lazyImage.classList.remove("lazy");
      //         lazyImageObserver.unobserve(lazyImage);
      //       }
      //     });
      //   });

      //   // Loop through and observe each image
      //   lazyImages.forEach(function (lazyImage) {
      //     lazyImageObserver.observe(lazyImage);
      //   });
      // } else {
      [].forEach.call(lazyImages, function (lazyImage) {
        const src = lazyImage.dataset.src;
        const img = new Image();

        img.onload = function () {
          lazyImage.src = src;
          lazyImage.classList.remove("lazy");
        };

        img.src = src;

        if (img.complete) {
          lazyImage.src = src;
          lazyImage.classList.remove("lazy");
        }
      });
      // }
    },

    bindScroll() {
      const container = this.$refs.container;
      if (!container) {
        return;
      }
      let isIE = navigator.userAgent.match(/MSIE (\d)/i);
      isIE = isIE ? isIE[1] : undefined;
      let isFF = /FireFox/i.test(navigator.userAgent);

      if (isIE < 9)
        //传统浏览器使用MouseWheel事件
        container.attachEvent("onmousewheel", function (e) {
          //计算鼠标滚轮滚动的距离
          //一格3行，每行40像素
          let v = e.wheelDelta / 2;
          container.scrollLeft += v;
          //阻止浏览器默认方法
          return false;
        });
      else if (!isFF)
        //除火狐外的现代浏览器也使用MouseWheel事件
        container.addEventListener(
          "mousewheel",
          function (e) {
            //计算鼠标滚轮滚动的距离
            let v = -e.wheelDelta / 2;
            container.scrollLeft += v;
            //阻止浏览器默认方法
            e.preventDefault();
          },
          false
        );
      //火狐使用DOMMouseScroll事件
      else
        container.addEventListener(
          "DOMMouseScroll",
          function (e) {
            //计算鼠标滚轮滚动的距离
            //一格是3行，但是要注意，这里和像素不同的是它是负值
            container.scrollLeft += e.detail * 80;
            //阻止浏览器默认方法
            e.preventDefault();
          },
          false
        );
    },
  },
};
</script>

<style lang="less" scoped>
.sidebar {
  box-sizing: border-box;
  width: 375px;
  padding: 20px 0 0;
  background: #2d2e30;
  position: fixed;
  z-index: 6;
  top: 0;
  left: -375px;
  bottom: 0;
  overflow: hidden;

  display: flex;
  flex-flow: column nowrap;
  transition: left ease 0.5s;

  &.open {
    left: 0;
  }
}

.sidebar-top {
  flex: 0 0 auto;
  text-align: right;
  padding: 0 18px;

  .close-icon {
    display: inline-block;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1) no-repeat url("~@/assets/ic_close.svg")
      center center;
  }
}

.user-info {
  flex: 0 0 auto;

  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;
  align-items: center;
  padding: 6px 20px 32px;

  .user-info-avatar {
    flex: 0 0 80px;
    height: 80px;
    border-radius: 50%;
    img {
      width: 100%;
    }
  }
  .user-info-text {
    padding-left: 13px;
    flex: 1 1 100%;

    color: #fff;
    font-weight: 600;
    font-size: 20px;

    &-desc {
      font-weight: normal;
      font-size: 16px;
    }
  }
}

.category-container {
  flex: 1 1 100%;
  padding: 10px 18px 0;
  overflow: hidden;

  display: flex;
  flex-flow: column nowrap;

  background: rgba(255, 255, 255, 0.05);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.category-list {
  flex: 0 0 auto;
  overflow: hidden;

  .category-wrapper {
    white-space: nowrap;
    overflow-x: auto;
    margin-bottom: -20px;
    padding-bottom: 20px;
    box-sizing: content-box;
  }
  .category-item {
    display: inline-block;
    padding: 4px 12px;
    border-radius: 16px;
    color: #fff;
    margin-right: 12px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    &.active {
      background: rgba(255, 255, 255, 0.2);
    }
  }
}

.album-container {
  flex: 1 1 0%;
  margin-top: 20px;
  overflow-y: auto;
}

.arrow-icon {
  margin-left: 10px;
  cursor: pointer;
}
</style>
