
import { defineComponent } from "vue";
import { message } from "ant-design-vue";
import moment from "moment";
import { mapState } from "vuex";
import { deleteReq, put, videoServer } from "@/util";

export default defineComponent({
  props: {
    comment: {
      type: Comment,
      required: true,
    },
    like: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      imgReady: false,
    };
  },
  computed: mapState({
    userInfo: (state: any) => state.userInfo,
  }),
  mounted() {
    const src = this.user?.head_img_url;
    if (src) {
      const img = new Image();

      img.onload = () => {
        this.imgReady = true;
      };

      img.src = src;

      if (img.complete) {
        this.imgReady = true;
      }
    }
  },
  methods: {
    formatDate(date: string) {
      if (date) {
        const _date = moment(date);
        const now = moment();
        let format = "";
        if (_date.diff(now, "year")) {
          format += "YYYY年";
        }
        format += "MM月DD日";
        return _date.format(format);
      }
      return "";
    },
    deleteComment() {
      const commentId = this.comment?.id;
      if (commentId) {
        deleteReq(`${videoServer}/v1/api/comments/${commentId}`).then(() => {
          message.success("删除成功");
          this.$emit("reload");
        });
      }
    },
    toggleLike() {
      const commentId = this.comment?.id;
      const like = this.like;
      if (commentId) {
        put(`${videoServer}/v1/api/like/comment/${commentId}`, {
          action: like ? 0 : 1,
        }).then(() => {
          // message.success("操作成功");
          this.$emit("reload");
        });
      }
    },
  },
  setup() {
    return {};
  },
});
