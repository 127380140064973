<template>
  <div class="comment-item">
    <div class="comment-item-user-avatar">
      <img :src="user.head_img_url" class="avatar" v-if="imgReady" />
      <img src="@/assets/default_avatar@2x.png" class="avatar" v-else />
    </div>
    <div class="comment-item-wrapper">
      <div>
        <div class="comment-item-user-name">{{ user.nickname }}</div>
        <div class="comment-item-date">
          {{ formatDate(comment.created_at) }}
        </div>
      </div>
      <div class="comment-item-content">
        {{ comment.content }}
      </div>
    </div>
    <div class="comment-item-suffix">
      <div class="comment-iten-like">
        <span>{{ comment.like_num }}</span>
        <span
          :class="['comment-item-like-icon', { liked: like }]"
          @click="toggleLike"
        ></span>
      </div>
      <div
        class="comment-item-delete-btn"
        v-if="userInfo?.id === user.id"
        @click="deleteComment"
      >
        删除
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { message } from "ant-design-vue";
import moment from "moment";
import { mapState } from "vuex";
import { deleteReq, put, videoServer } from "@/util";

export default defineComponent({
  props: {
    comment: {
      type: Comment,
      required: true,
    },
    like: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      imgReady: false,
    };
  },
  computed: mapState({
    userInfo: (state: any) => state.userInfo,
  }),
  mounted() {
    const src = this.user?.head_img_url;
    if (src) {
      const img = new Image();

      img.onload = () => {
        this.imgReady = true;
      };

      img.src = src;

      if (img.complete) {
        this.imgReady = true;
      }
    }
  },
  methods: {
    formatDate(date: string) {
      if (date) {
        const _date = moment(date);
        const now = moment();
        let format = "";
        if (_date.diff(now, "year")) {
          format += "YYYY年";
        }
        format += "MM月DD日";
        return _date.format(format);
      }
      return "";
    },
    deleteComment() {
      const commentId = this.comment?.id;
      if (commentId) {
        deleteReq(`${videoServer}/v1/api/comments/${commentId}`).then(() => {
          message.success("删除成功");
          this.$emit("reload");
        });
      }
    },
    toggleLike() {
      const commentId = this.comment?.id;
      const like = this.like;
      if (commentId) {
        put(`${videoServer}/v1/api/like/comment/${commentId}`, {
          action: like ? 0 : 1,
        }).then(() => {
          // message.success("操作成功");
          this.$emit("reload");
        });
      }
    },
  },
  setup() {
    return {};
  },
});
</script>

<style lang="less" scoped>
.comment-item {
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;
  padding: 12px 22px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
}
.comment-item-user-avatar {
  .avatar {
    display: inline-block;
    width: 40px;
    height: 40px;
  }
}
.comment-item-wrapper {
  flex: 1 1 100%;
  overflow: hidden;
  padding-left: 12px;
  color: rgba(255, 255, 255, 0.8);

  .comment-item-user-name {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
  }

  .comment-item-date {
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    color: rgba(255, 255, 255, 0.6);
  }

  .comment-iten-content {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  .comment-item-content {
    padding-top: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.8);
  }
}
.comment-item-suffix {
  display: flex;
  flex-flow: column nowrap;
  flex: 0 0 auto;
  .comment-iten-like {
    font-size: 12px;
    line-height: 14px;
    white-space: nowrap;
    padding-top: 6px;

    color: rgba(255, 255, 255, 0.6);

    span {
      vertical-align: middle;
    }
  }

  .comment-item-like-icon {
    background: no-repeat url("~@/assets/ic_like.svg") center center / 15px;
    display: inline-block;
    width: 15px;
    height: 15px;
    cursor: pointer;
    margin-left: 5px;
    &.liked {
      background: no-repeat url("~@/assets/ic_liked.svg") center center / 15px;
    }
  }

  .comment-item-delete-btn {
    white-space: nowrap;
    margin-top: auto;
    color: rgba(240, 70, 80, 0.8);
    cursor: pointer;
  }
}
</style>
