<template>
  <div class="comment-list-wrapper">
    <div class="comment-top-bar">
      <!-- <div class="comment-top-bar-left">
        <div class="comment-top-item">推荐</div>
        <div class="comment-top-item active">最新</div>
      </div> -->
      <div class="comment-top-bar-right">
        <i-button type="primary" size="xs" @click="showModal = true"
          >发表评论</i-button
        >
      </div>
    </div>
    <div v-if="!loading && comments.length === 0" class="comment-list-empty">
      暂无评论
    </div>
    <div v-else class="comment-list-content">
      <CommentItem
        v-for="comment in comments"
        :key="comment.comment.id"
        :comment="comment.comment"
        :user="comment.user"
        :like="comment.like"
        @reload="fetchData"
      />
    </div>
    <div class="comment-list-bottom" v-if="total">
      <a-pagination
        v-model:current="pageNum"
        v-model:pageSize="pageSize"
        :total="total"
        @change="onChange"
      ></a-pagination>
    </div>
    <a-modal
      :title="null"
      :footer="null"
      class="commentDialog"
      width="450px"
      :visible="showModal"
      @cancel="showModal = false"
      :afterClose="(text = '')"
    >
      <div class="modal-header">发表评论</div>
      <textarea
        rows="3"
        v-model="text"
        placeholder="听了你有什么话说"
        class="comment-textarea"
      />
      <div class="modal-footer">
        {{ textRemain > 0 ? textRemain : `超过${Math.abs(textRemain)}个字` }}
      </div>
      <i-button
        type="primary"
        style="width: 100%"
        size="small"
        @click="submitComment"
        >评论</i-button
      >
    </a-modal>
  </div>
</template>

<script>
import { defineComponent, nextTick } from "vue";
import { mapState } from "vuex";
import { get, post, videoServer } from "@/util";
import CommentItem from "@/components/CommentItem.vue";

export default defineComponent({
  components: { CommentItem },
  data() {
    return {
      pageNum: 1,
      pageSize: 20,
      loading: false,
      total: 0,
      comments: [],
      showModal: false,
      text: "",
      submitting: false,
    };
  },
  computed: {
    ...mapState({
      id: (state) => state.selectedVideo?.video?.id,
    }),
    textRemain() {
      return 140 - (this.text?.length || 0);
    },
  },
  watch: {
    id(id) {
      if (id) {
        this.pageNum = 1;
        this.fetchData();
      }
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async onChange() {
      await nextTick();
      this.fetchData();
    },
    submitComment() {
      const id = this.id;
      if (id) {
        if (this.submitting) {
          return false;
        }
        const remaining = this.textRemain;
        if (remaining < 0) {
          return this.$message.error("评论过长");
        }
        if (!this.text) {
          return this.$message.error("请输入评论");
        }

        this.submitting = true;
        post(`${videoServer}/v1/api/comments`, {
          video_id: id,
          content: this.text,
        })
          .then(() => {
            this.$message.info("发表成功!");
            this.fetchData();
            this.showModal = false;
            this.text = "";
          })
          .finally(() => {
            this.submitting = false;
          });
      }
    },

    fetchData() {
      const id = this.id;
      if (id) {
        this.loading = true;
        get(
          `${videoServer}/v1/api/video/${id}/comments`,
          {},
          {
            headers: {
              "page-num": this.pageNum,
              "page-size": this.pageSize,
            },
          }
        )
          .then(({ data, total_size }) => {
            this.comments = data;
            this.total = total_size;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
});
</script>

<style lang="less" scoped>
.comment-list-wrapper {
  padding: 16px 220px 16px 102px;
  @media screen and (max-width: 640px) {
    padding: 10vw 8vw;
  }
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  .comment-list-content {
    flex: 1 1 100%;
    overflow: auto;
  }
}

.comment-top-bar {
  &:after {
    clear: both;
    content: "";
    display: block;
  }
  margin-bottom: 16px;
}

.comment-top-bar-left {
  float: left;
  padding-top: 4px;
}
.comment-top-bar-right {
  float: right;
}

.comment-top-item {
  margin-right: 40px;
  display: inline-block;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  color: rgba(255, 255, 255, 0.6);

  &.active {
    font-weight: 600;
    color: #fff;
  }
}

.comment-list-bottom {
  padding-top: 18px;
  text-align: center;
}

.comment-list-empty {
  text-align: center;
  font-size: 24px;
  color: #fff;
  padding-top: 100px;
}
</style>

<style lang="less">
.comment-list-bottom {
  .ant-pagination-item {
    background: transparent !important;
    color: #fff !important;
    a {
      color: currentColor;
    }
    &:hover {
      color: #1cca96 !important;
      border-color: currentColor;
    }
  }
  .ant-pagination-item-ellipsis {
    color: #fff !important;
  }
  .ant-pagination-item-active {
    color: #1cca96 !important;
    border-color: currentColor;
  }
  .ant-pagination-item-link {
    background: transparent !important;
    color: #fff !important;
  }
}

.commentDialog {
  .ant-modal-close-icon {
    color: #fff;
  }
  .comment-textarea {
    border: 1px solid rgba(255, 255, 255, 0.6) !important;
    background: transparent;
    border-radius: 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
    padding: 16px;
    resize: none;
    outline: none;
    border: none;
    width: 100%;
    min-height: 165px;
    box-sizing: border-box;
  }

  .ant-modal-body {
    padding: 32px !important;
  }

  .modal-header {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #ffffff;
    padding-bottom: 28px;
  }

  .modal-footer {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    text-align: right;
    padding: 8px 0 32px;
    color: rgba(255, 255, 255, 0.6);
  }
}
</style>
