<template>
  <div class="login-page">
    <div class="login-page-left-part"></div>
    <div class="login-page-right-part">
      <router-link to="/" class="close-icon"></router-link>
      <div class="login-content">
        <img src="~@/assets/ic_playing.svg" />
        <h1 class="main-title"></h1>
        <h3 class="sub-title">加入来听森林之声</h3>
        <div>
          <i-button
            type="primary"
            :style="{ 'margin-bottom': '32px' }"
            class="button"
            @click="handleRegister"
          >
            注册
          </i-button>
        </div>
        <div>
          <i-button type="default" class="button" @click="handleLogin"
            >登录</i-button
          >
        </div>
        <div :class="['privacy', { checked }]" @click="checked = !checked">
          我已阅读并同意
          <a
            href="http://ubkare.com/service.html"
            rel="noreferrer noopener"
            target="_blank"
            @click.stop
          >
            服务条款
          </a>
          和
          <a
            href="http://ubkare.com/privacy.html"
            rel="noreferrer noopener"
            target="_blank"
            @click.stop
          >
            隐私权政策
          </a>
        </div>
        <div class="desc">可以用SLEEEPON账号进行登录</div>
      </div>
      <div class="login-footer">
        <div class="login-footer-title">其他方式</div>
        <div>
          <!-- <div class="facebook">Facebook</div> -->
          <div class="wechat" @click="wxLogin">Wechat</div>
        </div>
      </div>
    </div>
    <register-modal
      :visible="registerVisible"
      @cancel="registerVisible = false"
    />
    <login-modal
      :visible="loginVisible"
      @cancel="loginVisible = false"
      @register="
        () => {
          this.registerVisible = true;
          this.loginVisible = false;
        }
      "
      @forgetPassword="
        () => {
          this.$router.push('/forgetPassword');
        }
      "
    />
    <a-modal
      :visible="wechatVisible"
      title=""
      :footer="null"
      width="656px"
      class="wechat-modal"
      @cancel="wechatVisible = false"
    >
      <div id="wechatQrCode">
        <spinner />
      </div>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent, onUnmounted, onBeforeMount, nextTick } from "vue";

import RegisterModal from "@/components/RegisterModal.vue";
import LoginModal from "@/components/LoginModal.vue";
import Spinner from "@/components/Spinner.vue";

import { userServer, get } from "@/util";

export default defineComponent({
  components: {
    RegisterModal,
    LoginModal,
    Spinner,
  },
  data() {
    return {
      registerVisible: false,
      loginVisible: false,

      wechatVisible: false,

      checked: false,
    };
  },
  methods: {
    handleRegister() {
      if (this.checked) {
        this.registerVisible = true;
      } else {
        this.hint();
      }
    },
    handleLogin() {
      if (this.checked) {
        this.loginVisible = true;
      } else {
        this.hint();
      }
    },

    hint() {
      this.$message.info({
        content: "请先勾选页面的“同意并阅读服务条款和隐私政策”",
        icon: null,
        key: "login",
      });
    },

    async wxLogin() {
      if (this.checked) {
        this.wechatVisible = true;
      } else {
        this.hint();
        return;
      }
      await nextTick();

      if (window["WxLogin"]) {
        const obj = new window["WxLogin"]({
          id: "wechatQrCode",
          appid: "wx38b0f3e84ba285b4",
          scope: "snsapi_login",
          redirect_uri: encodeURIComponent(
            // `${window.location.origin.split('://')[1]}/signin?callback=wechat`
            `${window.location.origin}/login?callback=wechat`
          ),
          state: "",
          style: "white",
          href: "",
        });
      }
    },
  },
  mounted() {
    const query = this.$route.query;
    const { callback, code } = query;

    if (!callback) {
      return;
    }

    // weixin授权登录
    if (callback === "wechat" && code) {
      get(userServer + "/v1/api/fm/wx", {
        code,
      })
        .then((userInfo) => {
          // 获取用户信息
          if (userInfo) {
            // 第三方登录
            return get(userServer + "/v1/api/login/third", {
              type: 1,
              unionid: userInfo.unionid,
              head_img_url: userInfo.headimgurl,
              nickname: userInfo.nickname,
            });
          }
          return Promise.reject("获取微信用户信息失败");
        })
        .then((data) => {
          if (data) {
            this.$store.dispatch("afterLogin", data);
            this.$router.push("/");
          }
        })
        .catch((errMsg) => {
          this.$message.info(errMsg);
          this.$router.push("/login");
        });
    } else {
      this.$message.info("微信登录失败，请重新尝试");
      this.$router.push("/login");
    }
  },
  setup() {
    const html = document.querySelector("html");

    onBeforeMount(() => {
      html?.classList.add("login");
    });

    onUnmounted(() => {
      html?.classList.remove("login");
    });

    return {};
  },
});
</script>
<style lang="less" scoped>
.button {
  width: 40%;
  border-radius: 16px;
}

.login-page {
  height: 100%;

  background: no-repeat url("https://file.sleepon.cn/fm/bg_09-tuya.jpg") center
    center/ cover;
}

.login-page-left-part {
  float: left;
  height: 100%;
  width: 50%;
  background: no-repeat url("~@/assets/ic_playing.svg") center center;
}
.login-page-right-part {
  background: #202828;
  backdrop-filter: blur(30px);
  padding: 0 64px;
  float: right;
  height: 100%;
  width: 50%;
  box-sizing: border-box;

  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;

  .login-content {
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
  }

  .main-title {
    margin: 0;
    margin-top: 15px;
    height: 50px;
    background: no-repeat url("~@/assets/logo_iforestfm@2x.png") left top /
      252px;
  }

  .sub-title {
    font-weight: 600;
    font-size: 32px;
    color: #ffffff;
    margin-top: 46px;
    margin-bottom: 32px;
  }

  .desc {
    margin-top: 12px;
    padding-left: 5px;

    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
  }

  .privacy {
    font-size: 16px;
    line-height: 22px;
    padding-left: 30px;
    margin-top: 20px;
    cursor: pointer;

    color: rgba(255, 255, 255, 0.8);
    background: no-repeat url("~@/assets/ic_thick_unsel.svg") left 5px center;

    &.checked {
      background: no-repeat url("~@/assets/ic_thick_sel.svg") left 5px center;
    }

    a {
      color: #1cca96;
    }
  }
}

@media screen and (max-width: 640px) {
  .login-page-left-part {
    display: none;
  }
  .login-page-right-part {
    width: 100%;
    padding: 0 14vw;
  }
}

.login-footer {
  margin-bottom: 50px;
  .facebook,
  .wechat {
    padding-left: 48px;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
    min-height: 40px;
    line-height: 40px;
    display: inline-block;
  }
  .facebook {
    margin-right: 32px;
    background: no-repeat url("~@/assets/Facebook.svg") left center;
  }
  .wechat {
    background: no-repeat url("~@/assets/Wechat.svg") left center;
  }
}
.login-footer-title {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 15px;
}

.close-icon {
  position: absolute;
  top: 33px;
  right: 40px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1) no-repeat url("~@/assets/ic_close.svg")
    center center;
}
</style>
<style lang="less">
.login,
.login body,
.login #app {
  height: 100%;
  overflow: hidden;
}

#wechatQrCode {
  text-align: center;
  position: relative;
  min-height: 300px;
}
.wechat-modal .ant-modal-close-x {
  color: #fff;
}
</style>
