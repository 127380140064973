
import { defineComponent } from "vue";
import { mapState } from "vuex";

export default defineComponent({
  props: {
    open: {
      type: Boolean,
      required: false,
      default: false,
    },
    isFavorited: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: mapState({
    userInfo(state: any) {
      return state.userInfo;
    },
  }),
  setup() {
    return {};
  },
});
