
import { defineComponent } from "vue";
import cx from "classnames";

export default defineComponent({
  name: "Button",
  props: {
    style: {
      type: Object,
      required: false,
    },
    type: {
      type: String,
      default: "default",
      validator(value: string) {
        return ["primary", "default"].includes(value);
      },
    },
    circle: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "default",
      validator(value: string) {
        return ["xs", "small", "large", "default"].includes(value);
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classList(): string {
      return cx("btn", "btn-" + this.type, "btn-" + this.size, {
        "btn-circle": this.circle,
        "btn-loading": this.loading,
        "btn-disabled": this.disabled,
      });
    },
  },
});
