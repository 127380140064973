import axios from "axios";
import store from "./store";

import { message } from "ant-design-vue";

export const videoServer =
  process.env.NODE_ENV === "production"
    ? "https://fm-api.sleepon.cn"
    : // : "http://m-ts.fm.sleepon.cn";
      "/video";
export const userServer =
  process.env.NODE_ENV === "production"
    ? "https://user-api.sleepon.cn"
    : // : "http://m-ts.user.sleepon.cn";
      "/user";

const instance = axios.create({
  timeout: 10000,
});

// const TOKEN =
// "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2MzgzNDM5NTAsInVpZCI6MTAwODM5fQ.xzSDaSh3iEnt3T1a4VDEjuTHwfKNSu0nRBiLl01opTI";

export const scrollBody = function (): void {
  const el = document.documentElement;

  const height = window.innerHeight;
  const scrollTop = el?.scrollTop || 0;

  if (height - scrollTop > 0 && el) {
    setTimeout(() => {
      const speed = Math.max((height - scrollTop) / 3, 10);
      const target = scrollTop + speed;
      el.scrollTo(0, target);

      scrollBody();
    }, 1000 / 60);
  }
};

export const formatDate = (date: string): string => {
  const dateObj = new Date(date);

  return `${dateObj.getFullYear()}年${
    dateObj.getMonth() + 1
  }月${dateObj.getDate()}日`;
};

instance.interceptors.request.use(
  (config) => {
    const token = store.state.token;
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    if (response.status === 200 && response.data && response.data.code === 0) {
      const data = response.data.details || response.data.detail;
      return Promise.resolve(data);
    } else {
      message.error(response.data?.message);
      return Promise.reject(response);
    }
  },
  // 服务器状态码不是200的情况
  (error) => {
    if (error.response.status) {
      if (error.response.status > 400 && error.response?.data?.message) {
        message.error(error.response?.data?.message);
        return Promise.reject(error.response?.data?.message);
      }
      return Promise.reject(error.response);
    }
  }
);

export function get(url: string, params = {}, options = {}): Promise<any> {
  return new Promise((resolve, reject) => {
    instance
      .get(url, {
        params: params,
        ...options,
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}

export function post(url: string, params = {}): Promise<any> {
  return new Promise((resolve, reject) => {
    instance
      .post(url, params)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}

export function put(url: string, params = {}): Promise<any> {
  return new Promise((resolve, reject) => {
    instance
      .put(url, params)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}

export function deleteReq(url: string, params = {}): Promise<any> {
  return new Promise((resolve, reject) => {
    instance
      .delete(url, params)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}
