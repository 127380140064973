import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Button from "@/components/Button.vue";
import {
  Drawer,
  Modal,
  Input,
  Select,
  Form,
  Pagination,
  message,
} from "ant-design-vue";

import "normalize.css";
import "./global.less";

const app = createApp(App);

app.config.globalProperties.$message = message;

app
  .component("i-button", Button)
  .component("i-input", Input)
  // import antd-vue Components
  .use(Drawer)
  .use(Pagination)
  .use(Modal)
  .use(Input)
  .use(Select)
  .use(Select.Option)
  .use(Form)
  .use(Form.FormItem)
  .use(store)
  .use(router)
  .mount("#app");
