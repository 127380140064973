<template>
  <div class="ablum-wrapper" @click="$emit('select')">
    <div :class="['album-item', { active: isActive }]">
      <div class="album-item-playing" v-if="isActive && isPlaying">
        <playing-icon />
      </div>
      <img
        ref="img"
        :data-src="video.video.cover_url"
        :class="['album-item-cover', 'lazy']"
      />
      <div class="album-item-desc">
        <div class="title">{{ video.video.video_title }}</div>
        <div class="sub-title">
          {{ video.video_lan.video_title_cn }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PlayingIcon from "@/components/PlayingIcon.vue";

export default defineComponent({
  props: {
    video: {
      type: Object,
      required: true,
    },
    isPlaying: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PlayingIcon,
  },
});
</script>

<style lang="less">
.lazy {
  display: none;
}

.ablum-wrapper {
  width: 33.33%;
  float: left;
}

.album-item {
  margin-left: auto;
  margin-right: auto;
  width: 102px;
  height: 136px;
  margin-bottom: 32px;
  overflow: hidden;
  position: relative;
  border-radius: 16px;
  border: 1px solid transparent;
  position: relative;
  &.active {
    border: 1px solid rgba(255, 255, 255, 0.8);
  }
  &-playing {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    .bars {
      transform: scale(0.5);
      transform-origin: 50% 50%;
      margin-top: -20px;
    }
  }
}

.album-item-cover {
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
}

.album-item-desc {
  position: absolute;
  left: 5px;
  bottom: 6px;
  color: #fff;
  .title {
    font-weight: 600;
    font-size: 8px;
  }

  .sub-title {
    font-weight: 300;
    font-size: 7px;
  }
}
</style>
