import { createStore } from "vuex";
import moment from "moment";
import router from "@/router";
import { get, put, userServer, videoServer } from "@/util";

interface State {
  recommends: VideoItem[];
  history: VideoItem[];
  collection: VideoItem[];
  categories: Category[];

  selectedCategory: number;
  selectedVideo: Video | null;
  selectedVideoIndex: number | null;

  playingCategory: number;
  isPlaying: boolean;

  userInfo: UserInfo | null;
  token: string;
  showEmailHint?: boolean;
}

const token = window.localStorage.getItem("token");

export default createStore<State>({
  state: {
    recommends: [] as VideoItem[],
    categories: [],

    history: [] as VideoItem[],
    collection: [] as VideoItem[],

    selectedCategory: 0,
    selectedVideo: null,
    selectedVideoIndex: null,

    playingCategory: 0,
    isPlaying: false,

    userInfo: null,
    token: token || "",
    showEmailHint: undefined,
  },
  mutations: {
    SET_USER_INFO(state, payload) {
      state.userInfo = payload;
      if (payload) {
        const birth = payload.birth;

        if (birth && moment(birth).get("year") === 1) {
          if (state.userInfo?.birth) {
            state.userInfo.birth = moment("1970-01-01 00:00:00Z")
              .utc()
              .format();
          }
        }
      }
    },

    SET_HISTORY(state, payload) {
      state.history = payload;
    },

    SET_COLLECTION(state, payload) {
      state.collection = payload;
    },

    SET_CATEGORIES(state, payload) {
      state.categories = payload;
      state.playingCategory = state.selectedCategory;
      state.selectedVideo = payload?.[state.selectedCategory]?.list?.[0];
      state.selectedVideoIndex = 0;
    },

    SELECT_CATEGORY(state, { payload }) {
      state.selectedCategory = payload.index;
      if (payload.autoPlayFirst) {
        state.playingCategory = state.selectedCategory;
        state.selectedVideo = payload?.[state.selectedCategory]?.list?.[0];
        state.selectedVideoIndex = 0;
      }
    },

    SELECT_VIDEO(state, { payload }) {
      state.selectedVideo = payload.video;
      state.selectedVideoIndex = payload.index;

      if (payload.changeCategory) {
        state.playingCategory = state.selectedCategory;
      }

      if (payload.play && !state.isPlaying) {
        state.isPlaying = true;
      }
    },

    PLAY_VIDEO(state) {
      state.isPlaying = true;
    },

    PAUSE_VIDEO(state) {
      state.isPlaying = false;
    },

    SET_TOKEN(state, payload) {
      state.token = payload;
      if (payload === null) {
        localStorage.removeItem("token");
      } else {
        localStorage.setItem("token", payload);
      }
    },

    SHOW_EMAIL_HINT(state, payload) {
      if (
        payload?.email &&
        payload?.is_email_verified !== 1 &&
        state.showEmailHint === undefined
      ) {
        state.showEmailHint = true;
      }
    },

    HIDE_EMAIL_HINT(state) {
      state.showEmailHint = false;
    },
  },
  getters: {
    token(state) {
      return state.token;
    },

    categories(state) {
      if (state.userInfo) {
        const historyData = {
          category: {
            id: 1,
            pid: 1,
            level: 1,
            category_name: "我的历史",
            category_desc: "我的历史",
            status: 1,
          },
          list: state.history,
        };

        const collectionData = {
          category: {
            id: 2,
            pid: 2,
            level: 2,
            category_name: "我的收藏",
            category_desc: "我的收藏",
            status: 1,
          },
          list: state.collection,
        };

        return [collectionData, historyData, ...state.categories];
      }

      return state.categories;
    },

    playingVideos(state, getters) {
      const playingCategory = getters.categories[state.playingCategory];
      return playingCategory?.list || [];
    },

    categoryVideos(state, getters) {
      let category = state.selectedCategory;
      if (typeof category !== "number") {
        category = 0;
      }
      return getters.categories?.[category]?.list;
    },

    collectionVideoIds(state) {
      const collection = state.collection;
      return collection.map(({ video }) => video.id);
    },
  },
  actions: {
    selectVideo({ commit, getters }, { payload }) {
      commit({
        type: "SELECT_VIDEO",
        payload: {
          ...payload,
          categories: getters.categories,
        },
      });
    },

    toggleFavorited({ dispatch }, { payload: { id, action } }) {
      put(videoServer + "/v1/api/collect/" + id, {
        action,
      }).then(() => {
        dispatch("getCollections");
      });
    },

    addHistory({ dispatch, getters }, { payload: { id } }) {
      if (!getters.token) {
        return;
      }

      put(videoServer + "/v1/api/play/" + id, {
        action: 1,
      }).then(() => {
        dispatch("getHistory");
      });
    },

    getUserInfo({ commit, dispatch, getters }) {
      if (!getters.token) {
        return;
      }
      get(userServer + "/v1/api").then((userInfo) => {
        if (userInfo) {
          dispatch("getHistory");
          dispatch("getCollections");

          commit({
            type: "SELECT_CATEGORY",
            payload: {
              index: 2,
              autoPlayFirst: true,
            },
          });

          commit("SET_USER_INFO", userInfo);
          commit("SHOW_EMAIL_HINT", userInfo);
        }
      });
    },

    updateUserInfo({ commit }, { payload }) {
      put(userServer + "/v1/api", payload).then(() => {
        get(userServer + "/v1/api").then((userInfo) => {
          if (userInfo) {
            commit("SET_USER_INFO", userInfo);
          }
        });
      });
    },

    updateUserPassword({ dispatch }, { payload }) {
      put(userServer + "/v1/api/password", payload).then(() => {
        dispatch("logout");
      });
    },

    getHistory({ commit }) {
      get(userServer + "/v1/api/history").then((data) => {
        commit("SET_HISTORY", data.data);
      });
    },

    getCollections({ commit }) {
      get(userServer + "/v1/api/collects").then((data) => {
        commit("SET_COLLECTION", data.data);
      });
    },

    getData({ commit }) {
      get(videoServer + "/v1/api/videos").then((data) => {
        commit("SET_CATEGORIES", formatVideos(data));
      });
    },

    afterLogin({ commit, dispatch }, payload) {
      commit("SET_TOKEN", payload.token);
      commit("SET_USER_INFO", payload.user);

      dispatch("getUserInfo");
    },

    logout({ commit }) {
      commit("SET_TOKEN", null);
      commit("SET_USER_INFO", null);
      commit("SET_HISTORY", []);
      commit("SET_COLLECTION", []);

      router.push("/");
    },
  },
  modules: {},
});

// data transfer temp
const formatVideos = (data: any) => {
  const recommendCate = {
    category: {
      id: 0,
      pid: 0,
      level: 1,
      category_name: "今日推荐",
      category_desc: "今日推荐",
      status: 1,
    },
    list: data?.recommend,
  };

  return [recommendCate, ...data.main];
};
