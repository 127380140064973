<template>
  <side-bar
    :open="open"
    @closeSideBar="open = false"
    @openSettingBar="settingOpen = true"
  ></side-bar>
  <setting-bar
    :open="settingOpen"
    @closeSideBar="settingOpen = false"
  ></setting-bar>
  <comment-container :open="commentOpen" @close="commentOpen = false" />
  <div class="home">
    <top-bar
      @openSideBar="openSideBar"
      :open="open"
      :isFavorited="isFavorited"
      @toggleFavorited="toggleFavorited"
      @toggleComment="toggleComment"
    >
      <div class="home-title">
        <img src="@/assets/logo_iforestfm@2x.png" @click="goToHome" />
      </div>
    </top-bar>
    <swiper
      @swiper="setSwiperRef"
      :loop="true"
      :preventInteractionOnTransition="true"
      :preventClicksPropagation="true"
      :preventClicks="true"
      :class="['mySwiper']"
    >
      <swiper-slide v-for="v in videos" :key="v.video.id" @click.stop>
        <div
          :class="['video', isPlaying ? 'playing' : '']"
          :style="{ 'background-image': `url(${v.video.cover_url})` }"
        >
          <img
            :data-src="v.video.cover_url"
            class="swiper-lazy"
            style="display: none"
          />
          <!-- Preloader image -->
          <!-- <div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div> -->
          <div
            :class="['cover-title', { open }]"
            :style="commentOpen ? { bottom: bottomGap } : null"
          >
            <div class="video-desc">
              {{ v.video.video_author }} {{ v.video.geographic_location }}
              {{ v.video.video_desc }}
            </div>
            <div class="slide-hint">左右滑动切换白噪声</div>
          </div>
        </div>
      </swiper-slide>
      <div
        :class="['cover-wrapper', { open, commentOpen }]"
        :style="commentOpen ? { 'margin-bottom': bottomGap } : null"
      >
        <div
          class="swiper-prevButton"
          @click="slidePrev"
          v-show="videos && videos.length"
        ></div>
        <div
          class="swiper-nextButton"
          @click="slideNext"
          v-show="videos && videos.length"
        ></div>
        <div
          :class="[
            'state-icon',
            'swiper-no-swiping',
            isPlaying ? 'playing' : '',
          ]"
          @click="playClick"
          v-show="videos && videos.length"
        >
          <playing-icon v-if="isPlaying" />
        </div>
      </div>
      <spinner v-if="!(videos && videos.length)"></spinner>
    </swiper>
    <!-- <div :class="['top-cover', { open }]">
      <div class="swiper-prevButton" @click="slidePrev"></div>
      <div class="swiper-nextButton" @click="slideNext"></div>
      <div
        :class="['state-icon', isPlaying ? 'playing' : '']"
        @click="playClick"
      >
        <playing-icon v-if="isPlaying" />
      </div>
    </div> -->

    <video
      :src="selectedVideo?.video.video_url"
      ref="video"
      class="video-player"
      playsinline
      style="display: none"
    ></video>
  </div>
</template>

<script>
import { defineComponent, nextTick } from "vue";
import { mapGetters, mapState } from "vuex";
import enableInlineVideo from "iphone-inline-video";

import router from "@/router";

import TopBar from "@/components/TopBar.vue";
import SideBar from "@/components/SideBar.vue";
import SettingBar from "@/components/SettingBar.vue";
import PlayingIcon from "@/components/PlayingIcon.vue";
import Spinner from "@/components/Spinner.vue";
import CommentContainer from "@/components/CommentContainer.vue";

import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Lazy, Navigation, Virtual } from "swiper";

import "swiper/swiper.less";
import "swiper/components/lazy/lazy.less";
import "swiper/components/navigation/navigation.less";

SwiperCore.use([Lazy, Virtual, Navigation]);

let playPromise;

export default defineComponent({
  name: "Home",
  components: {
    Swiper,
    SwiperSlide,
    TopBar,
    SideBar,
    SettingBar,
    PlayingIcon,
    Spinner,
    CommentContainer,
  },
  data() {
    return {
      // navigationOptions: {
      //   nextEl: ".swiper-nextButton",
      //   prevEl: ".swiper-prevButton",
      // },
      open: false,
      settingOpen: false,
      commentOpen: false,

      bottomGap: 0,
    };
  },
  computed: {
    ...mapGetters({
      videos: "playingVideos",
      collectionVideoIds: "collectionVideoIds",
    }),
    ...mapState({
      userInfo(state) {
        return state.userInfo;
      },
      selectedCategory(state) {
        return state.selectedCategory;
      },
      selectedVideo(state) {
        return state.selectedVideo;
      },
      selectedVideoIndex(state) {
        return state.selectedVideoIndex;
      },
      isPlaying(state) {
        return state.isPlaying;
      },
      collectionVi(state) {
        return state.collection;
      },
    }),
    isFavorited() {
      const ids = this.collectionVideoIds;
      const selectedVideo = this.selectedVideo;

      return ids && ids.length && ids.indexOf(selectedVideo?.video.id) !== -1;
    },
  },
  watch: {
    open: {
      async handler() {
        await nextTick();
        this.swiper?.update();
        this.swiper?.updateSize();
      },
    },
    videos: {
      deep: true,
      async handler() {
        await nextTick();

        this.swiper.update();
        this.swiper.updateSlides();

        await nextTick();

        if (this.selectedVideo) {
          if (
            this.swiper &&
            this.selectedVideoIndex !== this.swiper?.realIndex
          ) {
            this.swiper.slideToLoop(this.selectedVideoIndex, 0, false);
          }
        }
      },
    },
    selectedVideoIndex: {
      handler(index) {
        if (this.selectedVideo) {
          if (this.swiper && index !== this.swiper?.realIndex) {
            this.swiper.slideToLoop(index, 0, false);
          }
        }
      },
    },
    selectedVideo: {
      deep: true,
      async handler() {
        this.pause();
        if (this.isPlaying) {
          await nextTick();
          this.$refs.video?.load?.();
          this.play();
        }
      },
    },
  },

  mounted() {
    this.$store.dispatch("getData");
    this.$store.dispatch("getUserInfo");

    this.swiper?.on("slideChangeTransitionEnd", async () => {
      const realIndex = this.swiper?.realIndex;

      this.$store.dispatch({
        type: "selectVideo",
        payload: {
          index: realIndex,
          video: this.videos[realIndex],
        },
      });
    });

    this.$refs.video?.addEventListener(
      "ended",
      () => {
        this.slideNext();
      },
      false
    );

    enableInlineVideo(this.$refs.video);
  },

  methods: {
    setSwiperRef(swiper) {
      this.swiper = swiper;
    },
    slidePrev() {
      this.swiper?.slidePrev(1000);
    },
    slideNext() {
      this.swiper?.slideNext(1000);
    },

    async play() {
      this.$store.commit({
        type: "PLAY_VIDEO",
      });

      await nextTick();

      // 历史tab点击播放不更新历史记录
      const isPlayingHistory = this.isPlayingHistory();
      if (!isPlayingHistory) {
        this.$store.dispatch({
          type: "addHistory",
          payload: {
            id: this.selectedVideo.video.id,
          },
        });
      }

      playPromise = this.$refs.video?.play?.();
    },

    async pause() {
      await nextTick();
      if (playPromise !== undefined) {
        playPromise.then(() => {
          this.$refs.video?.pause?.();
        });
      }
    },

    playClick(e) {
      e.stopPropagation();

      if (this.isPlaying) {
        this.$store.commit({ type: "PAUSE_VIDEO" });
        this.pause();
      } else {
        this.play();
      }
    },

    goToHome() {
      router.push("/");
    },

    toggleFavorited() {
      const currentVideo = this.selectedVideo;
      this.$store.dispatch({
        type: "toggleFavorited",
        payload: {
          id: currentVideo?.video.id,
          action: this.isFavorited ? 0 : 1,
        },
      });
    },

    openSideBar() {
      this.open = true;
      this.commentOpen = false;
    },

    toggleComment() {
      if (this.commentOpen) {
        this.bottomGap = 0;
        this.commentOpen = false;
      } else {
        this.settingOpen = false;
        this.open = false;
        this.commentOpen = true;
        this.bottomGap = window.innerHeight - 350 + "px";
      }
    },

    // 判断是否在History分类下播放
    isPlayingHistory() {
      return this.userInfo && this.selectedCategory === 1;
    },
  },
});
</script>

<style lang="less">
.swiper-container {
  background: #f5f5f5 no-repeat url("https://file.sleepon.cn/fm/bg_09-tuya.jpg")
    center center / cover;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  // z-index: -1;
}
.swiper-prevButton,
.swiper-nextButton {
  position: absolute;
  z-index: 3;
  top: 50%;
  display: inline-block;
  width: 60px;
  height: 60px;
  margin-top: -30px;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
}
.swiper-prevButton:before,
.swiper-nextButton:before {
  content: "";
  position: absolute;
  width: 200%;
  height: 200%;
  margin-left: -50%;
  margin-top: -50%;
  z-index: -1;
  background: rgba(0, 0, 0, 0.1);
  filter: blur(20px);
  border-radius: 50%;
}
.swiper-prevButton {
  left: 100 / 14.4vw;
  background: url("~@/assets/ic_back.svg") center center no-repeat;
}
.swiper-nextButton {
  right: 100 / 14.4vw;
  background: url("~@/assets/ic_right.svg") center center no-repeat;
}

.top-cover {
  position: absolute;
  // height: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;

  &.open {
    left: 375px;
  }
}

.video {
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #000;
  &.playing {
    // animation: breath ease 5s infinite;
  }
}

.video-player {
  display: none;
}

@keyframes breath {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.home-title {
  font-weight: 800;
  font-size: 46px;
  color: #fff;
  // margin-top: 23px;
  margin-left: auto;
  margin-right: auto;
  width: 252px;
  height: 50px;
  // background: no-repeat url("~@/assets/logo_iforestfm@2x.png") center top /
  //   252px;
  cursor: pointer;

  img {
    width: 252px;
    height: auto;
  }
}

.cover-title {
  font-weight: 300;
  font-size: 14px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  bottom: 36px;
  color: rgba(255, 255, 255, 0.8);

  transition: padding-left ease 0.5s, bottom ease 0.5s;

  &.open {
    padding-left: 375px;
  }

  .video-desc {
    padding-left: 15vw;
    padding-right: 15vw;
    box-sizing: border-box;
  }

  .slide-hint {
    font-weight: normal;
    font-size: 16px;
  }

  div {
    margin-top: 24px;
  }
}

.state-icon {
  cursor: pointer;
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  transform: translateX(-50%) translateY(-50%);
  background: no-repeat url("~@/assets/ic_play_large.svg") center center;

  opacity: 0.6;

  &.playing {
    background: transparent;
  }
}

.cover-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: margin-left ease 0.5s, margin-bottom ease 0.5s;
}

.cover-wrapper.open {
  margin-left: 375px;
}
</style>
