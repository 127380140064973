<template>
  <a-modal
    title=""
    :footer="null"
    :visible="visible"
    class="registerModal"
    width="656px"
    :closeIcon="null"
    @cancel="
      () => {
        this.$emit('cancel');
      }
    "
    :afterClose="afterClose"
  >
    <div class="modal-content">
      <template v-if="step === 1">
        <div class="modal-title">创建你的帐号</div>
        <a-form :form="form" layout="horizontal">
          <a-form-item label="" v-bind="validateInfos.nickname" hasFeedback>
            <a-input
              placeholder="昵称"
              v-model:value="modelRef.nickname"
              size="large"
            />
          </a-form-item>
          <a-form-item
            label=""
            v-if="registerType === 'phone'"
            v-bind="validateInfos.phone"
            hasFeedback
          >
            <a-input-group compact>
              <a-select
                v-model:value="areaCode"
                size="large"
                style="width: 25%"
                class="area-select"
              >
                <a-select-option value="86">CN+86</a-select-option>
              </a-select>
              <a-input
                placeholder="手机"
                size="large"
                style="width: 75%"
                class="phone-input"
                v-model:value="modelRef.phone"
              />
            </a-input-group>
          </a-form-item>
          <a-form-item label="" v-else v-bind="validateInfos.email" hasFeedback>
            <a-input
              placeholder="邮箱"
              size="large"
              v-model:value="modelRef.email"
            />
          </a-form-item>
          <a-form-item label="" v-bind="validateInfos.password" hasFeedback>
            <a-input-password
              placeholder="密码"
              size="large"
              v-model:value="modelRef.password"
            />
          </a-form-item>
          <div class="link-text">
            <span @click="changeType">
              {{ registerType === "phone" ? "改用电子邮件" : "改用手机号码" }}
            </span>
          </div>
        </a-form>
        <i-button
          class="bottomButton"
          type="primary"
          style="width: 100%"
          @click="createAccount"
          >创建帐号</i-button
        >
      </template>

      <template v-if="step === 2">
        <div class="modal-title">输入验证码</div>
        <div v-if="registerType === 'phone'" class="vcode-text">
          我们已经将4位验证码发送到你的手机 +{{ areaCode }} {{ modelRef.phone }}
        </div>
        <!-- <div v-if="registerType === 'mail'" class="vcode-text">
          我们已经将4位验证码发送到你的邮箱 {{ email }}
        </div> -->
        <a-form :form="form" layout="horizontal">
          <a-form-item label="" hasFeedback v-bind="validateInfos.code">
            <a-input placeholder="验证码" v-model:value="modelRef.code" />
          </a-form-item>
        </a-form>
        <div class="link-text" @click="handleResend">
          {{ time === 0 ? "没有收到验证码?" : time + "s" }}
        </div>
        <i-button
          type="primary"
          style="width: 100%"
          @click="nextStep"
          class="bottomButton"
          >下一步</i-button
        >
      </template>

      <template v-if="step === 3">
        <div class="success-content">
          <div style="margin-bottom: 32px">
            <img src="@/assets/ic_playing.svg" />
          </div>
          <di class="success-text">欢迎加入iForest FM</di>
        </div>
        <i-button
          type="primary"
          style="width: 100%"
          @click="complete"
          class="bottomButton"
          >去探索森林</i-button
        >
      </template>
    </div>
  </a-modal>
</template>

<script>
import sha1 from "sha1";
import { Form } from "ant-design-vue";
import { reactive, nextTick } from "vue";
import { post, userServer } from "@/util";

const useForm = Form.useForm;

export default {
  name: "RegisterModal",

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      step: 1,
      registerType: "phone", // phone | mail
      areaCode: "86",

      time: 0,
    };
  },

  methods: {
    changeType() {
      if (this.registerType === "phone") {
        this.registerType = "mail";
      } else {
        this.registerType = "phone";
      }
    },

    createAccount() {
      let names = ["nickname", "password"];
      if (this.registerType === "phone") {
        names.push("phone");
      } else {
        names.push("email");
      }

      this.validate(names).then((values) => {
        const params = { ...values };

        if (this.registerType === "phone") {
          this.sendCode({
            area_code: this.areaCode,
            phone: values.phone,
          });
          this.step = 2;
        } else {
          // 邮箱注册
          params.type = 1;
          this.registerRequest(params);
        }
      });
    },

    nextStep() {
      this.validate(["code"]).then(() => {
        // 短信注册
        const params = { ...this.modelRef, type: 2, area_code: this.areaCode };
        this.registerRequest(params);
      });
    },

    complete() {
      this.$emit("cancel");
      this.$router.push("/");
    },

    registerRequest(params) {
      params.password = sha1(params.password);
      post(userServer + "/v1/api/register", params).then(
        (data) => {
          // 显示成功界面
          this.step = 3;

          this.$store.dispatch("afterLogin", data);
        },
        (data) => {
          if (data?.message === "account has been registered") {
            this.$message.info(
              "该" +
                (this.registerType === "phone" ? "手机" : "邮箱") +
                "已被注册"
            );
            return;
          }
        }
      );
    },
    timer() {
      clearTimeout(this.$timer);
      if (this.time > 0) {
        this.$timer = setTimeout(() => {
          this.time -= 1;
          this.timer();
        }, 1000);
      }
    },

    sendCode(params) {
      post(userServer + "/v1/api/code", {
        type: 2,
        logic: 2,
        ...params,
      }).then(() => {
        this.time = 60;
        this.timer();
      });
    },

    async handleResend() {
      if (this.time === 0) {
        this.sendCode({
          area_code: "86",
          phone: this.modelRef.phone,
        });
      }
    },

    afterClose() {
      this.step = 1;
      this.registerType = "phone"; // phone | mail
      this.areaCode = "86";
      this.time = 0;
      this.resetFields();
    },
  },

  setup(props, context) {
    const modelRef = reactive({
      nickname: "",
      phone: "",
      email: "",
      password: "",
      code: "",
    });

    const rulesRef = reactive({
      nickname: [
        {
          required: true,
          message: "请输入昵称",
        },
        {
          max: 32,
          message: "昵称不能超过32个字符",
        },
      ],
      phone: [
        {
          required: true,
          message: "请输入手机号码",
        },
        {
          pattern:
            /^(13[0-9]|14[5|7]|15[0|1|2|3|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/,
          message: "请输入有效的手机",
        },
      ],
      email: [
        {
          required: true,
          message: "请输入邮箱",
        },
        {
          type: "email",
          message: "请输入有效的邮箱",
        },
      ],
      password: [
        {
          required: true,
          message: "请输入密码",
        },
        {
          min: 6,
          message: "密码最少6个字符",
        },
        {
          max: 32,
          message: "密码不能超过32个字符",
        },
      ],
      code: [
        {
          required: true,
          message: "请输入验证码",
        },
      ],
    });

    const { validate, validateInfos, resetFields } = useForm(
      modelRef,
      rulesRef
    );

    return {
      validate,
      validateInfos,
      modelRef,
      resetFields,
    };
  },
};
</script>

<style lang="less" scoped>
.modal-content {
  height: 660px;
  display: flex;
  flex-flow: column nowrap;

  .bottom-button {
    margin-top: auto;
  }
}

.modal-title {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: #ffffff;
  margin-bottom: 32px;
}

.link-text {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #1cca96;
  cursor: pointer;
}

.vcode-text {
  color: #fff;
  font-size: 16px;
  margin-bottom: 20px;
}

.success-content {
  text-align: center;
  margin-top: auto;
  .success-text {
    font-size: 36px;
    color: #fff;
  }
}
</style>

<style lang="less">
.registerModal {
  .ant-modal-close-x {
    display: none;
  }
  .ant-input-group {
    .ant-select-selector {
      color: #fff;
      border-color: rgba(255, 255, 255, 0.6) !important;
      background: transparent;
      height: 60px !important;
      padding-left: 25px !important;
      padding-top: 12px !important;
      padding-bottom: 11px !important;
      border-top-left-radius: 16px !important;
      border-bottom-left-radius: 16px !important;
      font-size: 18px;
      .ant-select-selection-item {
        line-height: 38px !important;
      }
    }
    .ant-select-focused .ant-select-selector,
    .ant-select-open .ant-select-selector,
    .ant-select-selector:hover {
      border-color: #fff !important;
    }
    .ant-select-suffix {
      color: #fff;
    }
    .ant-input {
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }
  .bottomButton {
    margin-top: auto;
  }
}
</style>
