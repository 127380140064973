<template>
  <div class="bars">
    <div class="bar"></div>
    <div class="bar"></div>
    <div class="bar"></div>
    <div class="bar"></div>
    <div class="bar"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    return {};
  },
});
</script>

<style>
.bars {
  height: 42px;
  left: 50%;
  margin: -30px 0 0 -20px;
  position: absolute;
  top: 50%;
  width: 40px;
  background: transparent;
}

.bar {
  background: #fff;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  bottom: 1px;
  height: 8px;
  position: absolute;
  width: 6px;
  animation: sound 0ms -800ms linear infinite alternate;
}

@keyframes sound {
  0% {
    height: 8px;
  }
  100% {
    height: 40px;
  }
}

.bar:nth-child(1) {
  left: 1px;
  animation-duration: 474ms;
}
.bar:nth-child(2) {
  left: 9px;
  animation-duration: 433ms;
}
.bar:nth-child(3) {
  left: 17px;
  animation-duration: 407ms;
}
.bar:nth-child(4) {
  left: 25px;
  animation-duration: 458ms;
}
.bar:nth-child(5) {
  left: 33px;
  animation-duration: 400ms;
}
</style>
