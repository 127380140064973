
import { defineComponent } from "vue";
import PlayingIcon from "@/components/PlayingIcon.vue";

export default defineComponent({
  props: {
    video: {
      type: Object,
      required: true,
    },
    isPlaying: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PlayingIcon,
  },
});
