<template>
  <div :class="['sidebar', { open }]">
    <div class="sidebar-top">
      <span class="back-icon" @click="$emit('closeSideBar')"></span>
      <span class="sidebar-top-text">个人设置</span>
      <span class="close-icon" @click="$emit('closeSideBar')"></span>
    </div>
    <div class="setting-container" v-if="userInfo">
      <div class="setting-item" @click="edit('nickname')">
        <div class="setting-item-content">
          <div class="setting-item-key">昵称</div>
          <div class="setting-item-value">{{ userInfo.nickname }}</div>
        </div>
        <span class="arrow-icon">
          <img src="@/assets/arrow.svg" />
        </span>
      </div>
      <div class="setting-item" @click="edit('birth')">
        <div class="setting-item-content">
          <div class="setting-item-key">生日</div>
          <div class="setting-item-value">
            {{ userInfo.birth ? formatDate(userInfo.birth) : "" }}
          </div>
        </div>
        <span class="arrow-icon">
          <img src="@/assets/arrow.svg" />
        </span>
      </div>
      <div class="setting-item" v-show="userInfo && userInfo.email">
        <div class="setting-item-content">
          <div class="setting-item-key">邮箱</div>
          <div class="setting-item-value">
            {{ userInfo.email || "暂无绑定" }}
          </div>
        </div>
        <!-- <span class="arrow-icon">
          <img src="@/assets/arrow.svg" />
        </span> -->
      </div>
      <div class="setting-item" v-show="userInfo && userInfo.phone">
        <div class="setting-item-content">
          <div class="setting-item-key">手机</div>
          <div class="setting-item-value">
            {{ userInfo.phone || "暂无绑定" }}
          </div>
        </div>
        <!-- <span class="arrow-icon">
          <img src="@/assets/arrow.svg" />
        </span> -->
      </div>
      <div class="setting-item" @click="edit('password')">
        <div class="setting-item-content">
          <div class="setting-item-key">修改密码</div>
        </div>
        <span class="arrow-icon">
          <img src="@/assets/arrow.svg" />
        </span>
      </div>
      <!-- <div class="setting-item" @click="edit('feedback')">
        <div class="setting-item-content">
          <div class="setting-item-key">反馈</div>
        </div>
        <span class="arrow-icon">
          <img src="@/assets/arrow.svg" />
        </span>
      </div> -->
    </div>
    <div class="setting-bottom">
      <i-button
        type="default"
        class="setting-bottom-button"
        size="small"
        @click="logout"
        >退出登录</i-button
      >
      <div class="setting-bottom-text">
        <div>
          <a
            href="http://ubkare.com/privacy.html"
            rel="noreferrer noopener"
            target="_blank"
          >
            隐私权政策
          </a>
        </div>
        <div>
          <a
            href="http://ubkare.com/service.html"
            rel="noreferrer noopener"
            target="_blank"
          >
            服务条款
          </a>
        </div>
      </div>
    </div>
  </div>
  <a-modal
    :title="null"
    :footer="null"
    :closable="false"
    :visible="editDialogVisible"
    width="450px"
    class="settingDialog"
    @cancel="editDialogVisible = false"
  >
    <div class="content-header">
      <span class="modal-title">{{ modalTitle }}</span>
      <span class="modal-close" @click="editDialogVisible = false"></span>
    </div>

    <template v-if="editKey === 'nickname'">
      <a-input
        v-model:value="editValue"
        size="small"
        placeholder="请输入昵称"
      />
    </template>
    <template v-if="editKey === 'birth'">
      <div class="birth-wrapper">
        <a-select type="text" v-model:value="birthMonth" size="small">
          <a-select-option
            v-for="month in monthArr"
            :value="month"
            :key="month"
            >{{ month }}</a-select-option
          >
        </a-select>
        <a-select type="text" v-model:value="birthDate" size="small">
          <a-select-option v-for="date in dateArr" :value="date" :key="date">{{
            date
          }}</a-select-option>
        </a-select>
        <a-select type="text" v-model:value="birthYear" size="small">
          <a-select-option v-for="year in yearArr" :value="year" :key="year">{{
            year
          }}</a-select-option>
        </a-select>
      </div>
    </template>
    <template v-if="editKey === 'phone'">
      <a-input
        v-model:value="editValue"
        size="small"
        placeholder="请输入手机号"
      />
    </template>
    <template v-if="editKey === 'email'">
      <a-input
        v-model:value="editValue"
        size="small"
        placeholder="请输入邮箱"
      />
    </template>
    <template v-if="editKey === 'password'">
      <a-input-password
        type="password"
        v-model:value="old_password"
        size="small"
        placeholder="原密码"
      />
      <a-input-password
        type="password"
        v-model:value="new_password"
        size="small"
        placeholder="输入新密码"
      />
      <a-input-password
        type="password"
        v-model:value="confirm_password"
        size="small"
        placeholder="再次输入新密码"
      />
    </template>

    <template v-if="editKey === 'feedback'">
      <textarea
        rows="3"
        placeholder="请描述你遇到的问题，我们会为你解答"
        class="feedback-textarea"
      />
      <div class="feedback-title">上传截图</div>
      <div class="feedback-desc">请提供问题的截图，帮助我们更块的找到问题</div>
      <div>
        <span class="add-btn"> </span>
      </div>
    </template>

    <i-button
      type="primary"
      size="small"
      class="dialog-button"
      v-if="editKey === 'feedback'"
      >提交</i-button
    >
    <i-button
      type="primary"
      size="small"
      class="dialog-button"
      @click="submit"
      v-else
      >确定</i-button
    >
  </a-modal>
</template>

<script>
import sha1 from "sha1";
import { mapState } from "vuex";
import { formatDate } from "@/util";
import { nextTick } from "vue";
import moment from "moment";

const generateArr = (min, max) => {
  const arr = [];
  let i = min;
  while (i <= max) {
    arr.push(i);
    i++;
  }
  return arr;
};

export default {
  name: "SettingBar",
  props: {
    open: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      monthArr: generateArr(1, 12),
      dateArr: generateArr(1, 31),
      yearArr: generateArr(1970, 2021),
      editDialogVisible: false,
      modalTitle: "",
      editKey: "nickname",
      editValue: "",

      old_password: "",
      new_password: "",
      confirm_password: "",

      birthYear: "",
      birthMonth: "",
      birthDate: "",
    };
  },

  computed: {
    ...mapState({
      userInfo(state) {
        return state.userInfo;
      },
    }),
  },

  methods: {
    formatDate,
    edit(editKey) {
      this.editKey = editKey;
      this.editDialogVisible = true;
      switch (editKey) {
        case "nickname":
          this.modalTitle = "昵称";
          this.editValue = this.userInfo.nickname;
          break;
        case "phone":
          this.modalTitle = "手机";
          this.editValue = this.userInfo.phone;
          break;
        case "email":
          this.modalTitle = "邮箱";
          this.editValue = this.userInfo.email;
          break;
        case "birth":
          this.modalTitle = "生日";
          this.editValue = this.userInfo.birth;

          this.birthYear = new Date(this.editValue).getFullYear();
          this.birthMonth = new Date(this.editValue).getMonth() + 1;
          this.birthDate = new Date(this.editValue).getDate();
          break;
        case "password":
          this.modalTitle = "修改密码";
          this.old_password = "";
          this.new_password = "";
          this.confirm_password = "";
          break;
        case "feedback":
          this.modalTitle = "反馈";
          break;
        default:
          break;
      }
    },

    async logout() {
      this.$emit("closeSideBar");

      await nextTick();

      this.$store.commit("HIDE_EMAIL_HINT");
      this.$store.dispatch("logout");
    },

    submit() {
      const editKey = this.editKey;
      const editValue = this.editValue;
      const payload = {};

      if (editKey === "nickname") {
        if (!editValue) {
          this.$message.info("请输入昵称");
          return;
        }

        if (editValue.length < 3) {
          this.$message.info("昵称不能少于三个字符");
          return;
        }

        payload.nickname = editValue;
        payload.birth = moment(this.userInfo.birth).format("YYYY-MM-DD");
      } else if (editKey === "phone") {
        if (!editValue) {
          this.$message.info("请输入手机号");
          return;
        }
        if (
          !/^(13[0-9]|14[5|7]|15[0|1|2|3|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/.test(
            editValue
          )
        ) {
          this.$message.info("请输入正确手机号");
          return;
        }
        payload.phone = editValue;
        payload.nickname = this.userInfo.nickname;
        payload.birth = moment(this.userInfo.birth).format("YYYY-MM-DD");
      } else if (editKey === "email") {
        if (!editValue) {
          this.$message.info("请输入邮箱");
          return;
        }
        if (!/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(editValue)) {
          this.$message.info("请输入正确邮箱");
          return;
        }
        payload.email = editValue;
        payload.nickname = this.userInfo.nickname;
        payload.birth = moment(this.userInfo.birth).format("YYYY-MM-DD");
      } else if (editKey === "birth") {
        payload.birth = `${this.birthYear}-${this.birthMonth}-${this.birthDate}`;

        payload.nickname = this.userInfo.nickname;
        if (!moment(payload.birth, "YYYY-M-D").isValid()) {
          this.$message.info("请检查选择的日期是否正确");
          return;
        }
      } else if (editKey === "password") {
        if (!this.old_password) {
          this.$message.info("请输入密码");
          return;
        }

        if (!this.new_password) {
          this.$message.info("请输入新密码");
          return;
        }

        if (!this.confirm_password) {
          this.$message.info("请确认密码");
          return;
        }

        if (this.password === !this.confirm_password) {
          this.$message.info("密码输入不一致");
          return;
        }

        payload.old_password = sha1(this.old_password);
        payload.password = sha1(this.new_password);
        payload.confirm_password = sha1(this.confirm_password);

        this.$store
          .dispatch({
            type: "updateUserPassword",
            payload: {
              ...payload,
            },
          })
          .then(() => {
            this.editDialogVisible = false;
          });

        return;
      }

      this.$store
        .dispatch({
          type: "updateUserInfo",
          payload: {
            ...payload,
          },
        })
        .then(() => {
          this.editDialogVisible = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.sidebar {
  box-sizing: border-box;
  width: 375px;
  padding: 20px 0 0;
  background: #2d2e30;
  position: fixed;
  z-index: 6;
  top: 0;
  left: -375px;
  bottom: 0;
  overflow: hidden;

  display: flex;
  flex-flow: column nowrap;
  transition: left ease 0.5s;

  &.open {
    left: 0;
  }
}

.sidebar-top {
  flex: 0 0 auto;
  padding: 0 18px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: 45px;

  &-text {
    padding: 0 16px;
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    flex: 1 1 100%;
  }

  .back-icon,
  .close-icon {
    flex: 0 0 42px;
    height: 42px;
    border-radius: 50%;
  }

  .back-icon {
    background: rgba(255, 255, 255, 0.1) no-repeat
      url("~@/assets/ic_arrow_left.svg") center center;
  }

  .close-icon {
    background: rgba(255, 255, 255, 0.1) no-repeat url("~@/assets/ic_close.svg")
      center center;
  }
}

.setting-container {
  flex: 1 1 100%;
  padding: 0 16px;
  overflow: auto;
}

.setting-item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 9px 12px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  margin-bottom: 16px;
  min-height: 68px;
  cursor: pointer;

  .arrow-icon {
    flex: 0 0 auto;
    &:hover {
      opacity: 0.8;
    }
  }

  &-content {
    flex: 1 1 100%;
    overflow: hidden;
  }

  &-key {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;

    color: #ffffff;
  }

  &-value {
    font-size: 16px;
    line-height: 22px;

    color: rgba(255, 255, 255, 0.8);
  }
}

.setting-bottom {
  flex: 0 0 auto;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  padding: 15px 16px 40px;

  color: #ffffff;

  &-button {
    width: 100%;
    color: #fff;
    font-weight: normal;
    border-color: #fff;
    font-size: 18px;
  }

  &-text {
    padding-top: 20px;
    display: flex;
    flex-flow: row nowrap;
    > div {
      flex: 0 0 50%;
    }
    a {
      color: inherit;
    }
  }
}

.content-header {
  margin-bottom: 30px;
  .modal-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #ffffff;
  }

  .modal-close {
    width: 14px;
    height: 14px;
    float: right;
    background: no-repeat url("~@/assets/ic_close.svg") center center;
    cursor: pointer;
  }
}

.dialog-button {
  width: 100%;
}

.feedback-textarea {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #909399;
  padding: 20px 16px;
  resize: none;
  outline: none;
  border: none;
  width: 100%;
  min-height: 165px;
  box-sizing: border-box;
}

.feedback-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin-top: 24px;
  color: #ffffff;
}

.feedback-desc {
  font-size: 14px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.8);
}

.add-btn {
  margin-top: 13px;
  background: rgba(255, 255, 255, 0.1) no-repeat url("~@/assets/ic_add.svg")
    center center;
  display: inline-block;
  width: 72px;
  height: 72px;
  cursor: pointer;
  border-radius: 16px;
  margin-bottom: 32px;
}
</style>

<style lang="less">
.settingDialog {
  .ant-modal-body {
    padding: 32px !important;
  }
  .ant-input {
    margin-bottom: 32px;
  }
  .ant-input-password {
    margin-bottom: 32px;
    padding: 0px 7px !important;
    .ant-input {
      margin-bottom: 0px;
    }
  }
}

.birth-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding-bottom: 40px;
  .ant-select {
    flex: 0 0 30%;
  }

  .ant-select-selector {
    color: #fff;
    background: transparent !important;
    height: 54px !important;
    padding-left: 25px !important;
    padding-top: 12px !important;
    padding-bottom: 11px !important;
    border-radius: 35px !important;
    .ant-select-selection-item {
      line-height: 30px !important;
    }
  }
  .ant-select-focused .ant-select-selector,
  .ant-select-open .ant-select-selector,
  .ant-select-selector:hover {
    border-color: #1cca96 !important;
  }
  .ant-select-suffix {
    color: #fff;
  }
}
</style>
