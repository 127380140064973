<template>
  <div :class="['about-topbar', { open }]">
    <div class="left" v-if="!open">
      <div
        :class="['icon', 'tree', { login: !!userInfo }]"
        @click="$emit('openSideBar')"
      ></div>
    </div>
    <div class="right">
      <div
        v-if="userInfo"
        :class="['icon like', { favorited: isFavorited }]"
        @click="$emit('toggleFavorited')"
      ></div>
      <div
        class="icon comment"
        v-if="userInfo"
        @click="$emit('toggleComment')"
      ></div>
      <!-- <div class="icon share"></div> -->
    </div>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapState } from "vuex";

export default defineComponent({
  props: {
    open: {
      type: Boolean,
      required: false,
      default: false,
    },
    isFavorited: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: mapState({
    userInfo(state: any) {
      return state.userInfo;
    },
  }),
  setup() {
    return {};
  },
});
</script>

<style lang="less">
.about-topbar:after {
  content: "";
  display: block;
  clear: both;
}

.about-topbar {
  position: relative;
  z-index: 3;
  padding: 24px;
  transition: margin-left ease 0.5s;

  &.open {
    margin-left: 375px;
  }
  .left {
    float: left;
  }
  .right {
    float: right;
  }
  .icon {
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    &.tree {
      &.login {
        background: url("~@/assets/default_avatar@2x.png") no-repeat center
          center / 50px 50px;
      }
      background: url("~@/assets/tree.svg") no-repeat center center;
    }
    &.like {
      background: rgba(0, 0, 0, 0.6) url("~@/assets/like.svg") no-repeat center
        center;
      margin-right: 24px;
      &.favorited {
        background-image: url("~@/assets/like_favorited.svg");
      }
    }
    &.comment {
      background: rgba(0, 0, 0, 0.6) url("~@/assets/ic_comment.svg") no-repeat
        center center;
      margin-right: 24px;
    }
    &.share {
      background: rgba(0, 0, 0, 0.6) url("~@/assets/share.svg") no-repeat center
        center;
    }
  }
}
</style>
