<template>
  <div :class="['comment-container-wrapper', { open }]" @click="$emit('close')">
    <div class="comment-container-mask"></div>
    <div class="comment-container" @click.stop>
      <comment-list></comment-list>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CommentList from "@/components/CommentList.vue";

export default defineComponent({
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CommentList,
  },
});
</script>

<style lang="less" scoped>
.comment-container-wrapper {
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  height: 0%;
  z-index: 2;
  &.open {
    height: 100%;
    .comment-container {
      transform: translateY(0%);
    }
    .comment-container-mask {
      position: fixed;
      width: 100%;
      height: 100%;
    }
  }
}

.comment-container-mask {
  height: 0;
}

.comment-container {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 0;
  height: calc(100% - 350px);
  transform: translateY(100%);
  transition: transform 0.5s cubic-bezier(0.7, 0.3, 0.1, 1);
  background-color: #132823;
}
</style>
